import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Skelton from '../views/Skelton.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '',
    component: Skelton,
    children: [
      {
        path: '/',
        name: 'Home',
        component: Home
      },
      {
        path: '/products',
        name: 'Products',
        component: () => import('../views/Products.vue')
      },
      {
        path: '/solutions',
        name: 'Solutions',
        component: () => import('../views/Solutions.vue')
      },
      {
        path: '/staffing',
        name: 'Staffing',
        component: () => import('../views/Staffing.vue')
      },
      {
        path: '/about',
        name: 'About',
        component: () => import('../views/About.vue')
      },
      {
        path: '/careers',
        name: 'Careers',
        component: () => import('../views/Careers.vue')
      },
      {
        path: '/contact',
        name: 'Contact',
        component: () => import('../views/Contact.vue')
      },
      {
        path: '/terms',
        name: 'Terms',
        component: () => import('../views/Terms.vue')
      },
      {
        path: '/privacy-policy',
        name: 'PrivacyPolicy',
        component: () => import('../views/PrivacyPolicy.vue')
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    window.scrollTo(0, 0);
  },
  routes
})

export default router
