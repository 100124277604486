import Vue from 'vue'
import axios from "axios";

import App from './App.vue'
import router from './router'

import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import './assets/ignitor.styl'
import './assets/sreevo.styl'

function sendStats(status, data) {
  console.log(data);
}

axios.interceptors.request.use(
  (config) => {
    if (
      (config.headers["Content-Type"] &&
        config.headers["Content-Type"].startsWith("application/json")) ||
      !config.headers["Content-Type"]
    ) {
      if (localStorage.getItem("Token") && !config.headers.Authorization) {
        config.headers.Authorization = `Bearer ${localStorage.getItem(
          "Token"
        ) || null}`;
      }
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axios.interceptors.response.use(null, (error) => {
  if (error.config && error.response) {
    switch (error.response.status) {
      case 400:
        if (typeof error.response.data === 'object') {
          Object.keys(error.response.data).forEach(
            (field) =>
            (error.response.data[field] =
              error.response.data[field][0] || error.response.data[field])
          );
        }
        error.response.data = error.response.data;
        break;
      case 401:
        localStorage.clear();
        window.location.href = "/";
        break;
      case 403:
        break;
      case 404:
      case 405:
      case 500:
        sendStats(error.response.status, error.response.data);
        break;
    }
  }
  return Promise.reject(error);
});

Vue.use(axios);

Vue.filter('reverse', function(value) {
  return value.slice().reverse();
});

/* Bind firebase to your Vue instance */
Vue.config.productionTip = false
Vue.config.ignoredElements = ['ion-icon']
Vue.prototype.$width = screen.width;
Vue.prototype.$devices = {
  tab: 768,
  maxTab: 1025,
  desktop: 1224
};

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')